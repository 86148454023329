<template lang="pug">
include ../../../configs/template
div.row
  div.col-sm-12
    +data-info('createDate', 'statement.created_at')
  div(v-if="statement.sailor").col-sm-12.col-md-6
    +data-info('sailorFullName', 'statement.sailor[`full_${nameLang}`]')
  div(v-if="statement.sailor").col-sm-12.col-md-6
    +data-info('sailorId', 'statement.sailor.id')
  div(v-if="statement.crewing_company").col-sm-12.col-md-6
    +data-info('crewing', 'statement.crewing_company')
  div(v-if="statement.manager_full_name").col-sm-12.col-md-6
    +data-info('crewingManager', 'statement.manager_full_name')
  div.col-sm-12.col-md-6
    +data-info('rank', 'getDirectoryObject({id: statement.rank, value: "ranks" })[nameLang]')
  div.col-sm-12.col-md-6
    b {{ $t('position') }}:
    span(v-for="position in statement.position" :key="position") {{ getDirectoryObject({ id: position, value: 'positions' })[nameLang] }};
  div.col-sm-12
    +data-info('sailorPassport', 'getDirectoryObject({id: statement.include_sailor_passport, value: "processingStatuses" })[nameLang]')
  div.col-sm-12
    +data-info('tableHeaders.status_document', 'getDirectoryObject({ value: "statuses", id: statement.status_document })[nameLang]')(:class="getStatus(statement.status_document)")
  div(v-if="statement.comments?.length").col-sm-12
    h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
    v-divider
    div(v-for="(comment, index) in statement.comments" :key="comment.id").ml-4
      div(v-if="checkAccess('backOffice')").pt-2.py-3
        +data-info('createdBy', 'comment.created_by.name')
      div.pt-2.py-3
        +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
      div.pt-2.py-3
        +data-info('reasonComment', 'comment.comment')
      v-divider(v-if="index !== statement.comments.length-1")
</template>

<script>
import { getStatus } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'PackageISCInfo',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getStatus,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject', 'statusById']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  }
}
</script>
